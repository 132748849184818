import React from 'react'
import {SidebarLinkRight,SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink} from './sidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="AboutUs" onClick={toggle}>About Us
                </SidebarLink>
                <SidebarLink to="OurTeam" onClick={toggle}>Our team
                </SidebarLink>
                <SidebarLink to="Investors" onClick={toggle}>Investors
                </SidebarLink>
                <SidebarLink to="Portfolio" onClick={toggle}>Portfolio
                </SidebarLink>
                <SidebarLinkRight to="contactForm" onClick={toggle}>Contact Us
                </SidebarLinkRight>
            </SidebarMenu>
        </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
