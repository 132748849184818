import React from 'react';
import {Logo, Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavRight, NavRightLink} from './navbarElements';
import {FaBars} from 'react-icons/fa';
import { animateScroll as scroll} from 'react-scroll';
import Image from '../../Photos/Picture1.jpg';

const toggleHome = () => {
    scroll.scrollToTop();
};

const Navbar = ({toggle}) => {
    return (
      <>
        <Nav>
            <NavbarContainer>
            <Logo to='/' onClick={toggleHome} src={Image} type='MountainBackground/jpeg' />
                <NavLogo to='/' onClick={toggleHome}>Camarico</NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='AboutUs' smooth={true} duration={500} spy={true} exact='true'>About Us</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='OurTeam' smooth={true} duration={500} spy={true} exact='true'>Our Team</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Investors' smooth={true} duration={500} spy={true} exact='true'>Investors</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Portfolio' smooth={true} duration={500} spy={true} exact='true'>Portfolio</NavLinks>
                    </NavItem>
                </NavMenu>
                <NavRight>
                <NavRightLink to='contactForm' smooth={true} duration={500} spy={true} exact='true'>Contact Us</NavRightLink>
                </NavRight>
            </NavbarContainer>
        </Nav>
      </>
    )
}

export default Navbar
