import React from 'react'
import Image from '../../Photos/MountainBackground.jpg'
import { HeroContainer, HeroBg, ImageBg, HeroContent, HeroH1, HeroP} from './heroElements';

const HeroSection = () => {
    return (
        <HeroContainer id="home">
            <HeroBg>
                <ImageBg src={Image} type='MountainBackground/jpeg' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Innovative Investment</HeroH1>
                <HeroP> Engaged in the integrated
                 development, aquisition and partnership
                  of intellectual property,
                   assets and portfolio interests 
                   </HeroP>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
