import emailjs from "emailjs-com"
import React from 'react'
import '../contactForm/contactcsss/contactStyles.css'
import {Heading} from '../contactForm/contactStyles.js'
import ScrollToTop from "./ScrollToTop";

export default function ContactUs() {

	function sendEmail(e) {
	    e.preventDefault();
    
	emailjs.sendForm('service_vc6bqzq', 'CamaricoTest', e.target, 'user_ArNIx5BsJbvIZ4UNZawKu')
	    .then((result) => {
		console.log(result.text);
	    }, (error) => {
		console.log(error.text);
	    });
	    e.target.reset()
	}

    
	return(
	<body>
	<ScrollToTop />
	    <div class="title">
	    <Heading to='/'>Camarico</Heading>
	    <h2>Fill out the form below to get in touch!</h2>
	    </div>
		<div class="contact-form">
		<form id="contact-form"onSubmit={sendEmail}>
			
			<input type="text"  class="form-control" placeholder="Name" name="name" required/><br></br>
			   
			<input type="email" class="form-control" placeholder="Email Address" name="email" required/><br></br>
			    
			<input type="text"  class="form-control" placeholder="Subject" name="subject"/><br></br>
			   
			<textarea  rows="4" class="form-control" placeholder="Your message" name="message" required></textarea><br></br>
			    
			<input type="submit" class="form-control submit" value="Send Message"></input>    
		    </form>
		    </div>
	</body>
	)
    }



