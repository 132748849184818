import styled from 'styled-components';

export const PortfolioContainer = styled.div`
height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
justify-conent: center;
align-items: center;
background: #2F2F41;

@media screen and (max-width:768px){
	height: 900px;
}

@media screen and (max-width:480px){
	height: 900px;
}
`;

export const PortfolioWrapper = styled.div`
max-width: 1000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center;
grid-gap: 16px;
padding: 25px;

@media screen and (max-width: 1000px){
	grid-template-columns: 1fr;
	
}

@media screen and (max-width: 768px){
	grid-template-columns: 1fr;
}
`;


export const PortfolioCard = styled.div`
background: white;
width: 310px;
height: 340px;
display: flex;
flex-direction: column;
justify-content: flex-start;
border-radius: 20px;
max-height: 500px;
padding: 10px;
transition: all 0.2s ease-in-out;
color: #2F2F41;

&:hover{
	transform: scale(1.02);
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}
`;

export const PortfolioImg = styled.img`
width: auto;
height: 120px;
margin-left: auto;
margin-right: auto;
margin-bottom: 10px;
align: center;
`;

export const PortfolioH1 = styled.h1`
font-size: 48px;
color: white;
padding-top:100px;
margin-bottom: 15px;

@media screen and (max-width: 480px){
	font-size:2rem;
}
`;

export const PortfolioH2 = styled.h2`
font-size: 20px;

position: center;
text-align: center;
bottom: 0px;
`;

export const PortfolioH2Wrap = styled.div`
height: 35px;
background: Transparent;
margin-bottom: 10px;
position: relative;
text-align: left;
`;

export const PortfolioP2 = styled.p`
font-size: 15px;
text-align: center;
height:33px;
padding-top: 20px;
padding-bottom: 40px;
border-top: solid #9FC756 1px;  
`;

export const PortfolioP3 = styled.p`
font-size: 15px;
text-align: center;
height: 33px;
padding-bottom:45px;
`;

export const PortfolioP4 = styled.p`
font-size: 15px;
height:33px;
text-align: center;


`;
