import React from 'react'
import {TeamContainer,TeamH1, TeamWrapper, TeamCard, TeamH2,TeamP3 ,TeamP2 } from './teamElements';

const TeamSection = ({toggle}) => {
	return (
	<>
	<TeamContainer id="OurTeam">		
		<TeamH1>Our Team</TeamH1>
		<TeamWrapper>
		<TeamCard>
			<TeamH2>Mr. R. Mackenzie Loree</TeamH2>
			<TeamP2>CEO &amp; Chairman of Board</TeamP2>
			<TeamP3>Mr. Loree has over 19 years experience in the energy, real estate, agriculture and investment industry,
both in the private and public markets. Through his career Mr. Loree has developed various energy
related patents and has been involved in the start up of multiple oil and gas service entities, exploration
companies, real estate holding and developments. Mr. Loree is currently the Managing Director and of
Arion Pacesetter Ltd an Alberta based investment firm and a large scale agricultural enterprise located in
southern Alberta.</TeamP3>
			</TeamCard>
			<TeamCard>
			<TeamH2>Mr. Charles Yang</TeamH2>
			<TeamP2>Independent Director</TeamP2>
			<TeamP3>Mr. Yang is an accomplished businessman in the field of operations, executive and project management.
Mr Yang was the General Manger of CIMTEK Group, Wuhan Twin Tigers Coatings Group and the
Marketing Director of Linuo Group. Linuo Group is a $1 billion multinational company covering solar
energy, borosilicate glass wares and pharmacies. Mr. Yang worked with the China National Electronics
Imp/Exp Company, A Leading, Beijing/Ji’nan, China, multinational company in electronical engineering,
ship-building; international trade in electrical, lighting, automation, consumer electronic and
communication products, parts and finished products with annual Sales over $10 billion.</TeamP3>
			</TeamCard>
			<TeamCard>
			<TeamH2>Len Tucker</TeamH2>
			<TeamP2>Independent Director</TeamP2>
			<TeamP3>Len brings over 17 years of investment management experience, including Investment Advisor roles at Merrill Lynch, CIBC Wood Gundy and RBC Dominion Securities in addition to a large scale institutional sized portfolio advisor role.  He also worked as an engineering project manager providing project management and equipment procurement expertise at the Department of National Defence. Enjoying new challenges and a variety of work, early in his career he worked as an environmental assessment scientist at the Canadian Coast Guard and the Department of Fisheries and Oceans, providing regulatory expertise in the Canadian mining and offshore oil and gas industry projects, such as the Terra Nova Offshore Petroleum Development Project and the Voisey's Bay Mine & Mill Project. </TeamP3>
			</TeamCard>
			<TeamCard>
			<TeamH2>Mr. Anthony Chan CPA, CA</TeamH2>
			<TeamP2>Chief Financial Officer</TeamP2>
			<TeamP3>Mr. Chan has over 30 years of experience in accounting and auditing as a Chartered Professional
Accountant (CPA, CA). Mr. Chan is the principal of a Canadian public accounting practice firm in
Vancouver, British Columbia, which is CPAB and PCAOB registrant.
His specialized areas of practice and experience includes; providing auditing services to public
companies listed on TSX, TSXV, CNSX, OTCBB &amp; Pink Sheets; providing CFO services to Canadian public
companies to ensure compliance with relevant financial reporting and regulatory requirements; and
providing consulting services and assistance to private companies preparing to be listed on various
Canadian Stock Exchanges through mergers &amp; acquisitions (RTO by shell and CPC companies).</TeamP3>
			</TeamCard>
		</TeamWrapper>

	</TeamContainer>		
	</>
	)
}

export default TeamSection
