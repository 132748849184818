import styled from 'styled-components';


export const HeroContainer = styled.div `
display: flex;
justify-conent: center;
height: 100vh;
width: 100vw;
position: relative;

`;

export const HeroBg = styled.div `
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100vw;
height: 100vh;
overflow: hidden;
`;

export const ImageBg = styled.img `
width: 100vw;
height: 100vh;
-o-object-fit: cover;
object-fit: cover;
background: white;
`;

export const HeroContent = styled.div `
z-index:3;
width: 100vw;
max-width: 100vw;
padding-top: 100px;
position: absolute;
display: flex;
flex-direction:column;
align-items:center;
`;

export const HeroH1 = styled.h1 `
color: #fff;
font-size: 64px;
text-align:center;

@media screen and (max-width: 768px){
    font-size: 44px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const HeroP = styled.p `
padding-top: 10px;
padding-bottom: 8px;
margin-top: 24px;
color: white;
font-size: 30px;
text-align: center;
max-width: 550px;
border-bottom: solid 2px white;
border-top: solid 2px white;
letter-spacing: 0.5px;
font-weight: bold; 



@media screen and (max-width: 768px){
    font-size: 24px;
    margin: 30px;
}

@media screen and (max-width: 480px){
    font-size: 20px;
    margin: 30px;
}
`;