import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom'

export const TeamContainer = styled.div`
height: 1650px;
width: 100vw;
display: flex;
flex-direction: column;
justify-conent: center;
align-items: center;
background: #2F2F41;

@media screen and (max-width: 950px){
	height: 2800px;
}
`;

export const TeamWrapper = styled.div`
max-width: 1000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr ;
align-items: center;
grid-gap: 16px;
padding: 25px;


@media screen and (max-width: 950px){
	grid-template-columns: 1fr ;
	padding-right: 35px;
	padding-left: 35px;
}
`;

export const TeamCard = styled.div`
background: white;
width: 700px;
height: 320px;
display: flex;
flex-direction: column;
justify-content: flex-start;
border-radius: 10px;
max-height: 650px;
padding: 20px;
transition: all 0.2s ease-in-out;
color: #2F2F41;

&:hover{
	transform: scale(1.02);
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

@media screen and (max-width: 950px){
	height: 610px;
	width: 250px;
}
`;

export const TeamH1 = styled.h1`
font-size: 48px;
color: white;
padding-top:100px;
margin-bottom: 15px;

@media screen and (max-width: 480px){
	font-size:2rem;
}
`;

export const TeamH2 = styled.h2`
font-size: 24px;
margin-bottom:3px;
text-align: center;
`;

export const TeamP2 = styled.p`
font-size: 16px;
text-align: center;
font-weight: bold;
padding-top: 10px;
border-top: solid #9FC756 1px;
`;

export const TeamP3 = styled.p`
font-size: 16px;
text-align: left;
padding-top: 10px;
line-height: 1.15rem;

`;

export const BioPage = styled(LinkR)`
  background: white;
  color: #2F2F41;
  text-decoration: none;
  padding: 10px;
  font-weight: bold;
  border: solid #9FC756 2px;
  border-radius: 20px;
  width: 100px;
  margin: auto;

&:hover {
    color: #9FC756;
    transition: 0.2s ease-in-out;
}
`;
