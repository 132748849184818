import React from 'react';
import Image from '../../Photos/Investors.svg'


import { InvestorContainer, InvestorWrapper, InvestorRow, Column1, TextWrapper, Heading, Subtitle, Column2,ImgWrap, Img } from './investorElements';
const InvestorSection = () => {
	return (
	<>
	<InvestorContainer id="Investors">
		<InvestorWrapper>
			<InvestorRow >
				<Column1>
				<TextWrapper>
					<Heading>Check us out on the CSE</Heading>
					<Subtitle> Click below to check out our current news releases, financials, and CSE monthly reports</Subtitle>
					<a id="link" href="https://thecse.com/en/listings/life-sciences/camarico-investment-group-ltd">Check it out!</a>
				</TextWrapper>
				</Column1>
				<Column2>
					<ImgWrap>
						<Img src={Image} type='Investors/svg' alt='Investors image'/>
					</ImgWrap>
				</Column2>
			</InvestorRow>
		</InvestorWrapper>
	</InvestorContainer>
	</>
	)
}

export default InvestorSection