import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav `
display: flex;
background: transparent;
background: white;
position: fixed;
width: 100vw;
height: 45px;
z-index:100;


@media screen and (max-width: 960px) {
    transistion: 0.8s all ease
}
`;

export const NavbarContainer = styled.div `
display: flex;
justify-content: space-between;
z-index: 100;
width: 100vw;
margin-top:10px;
z-index: 100;
`;
export const Logo = styled.img `
width: 35px;
height: 35px;
margin-left:10px;
margin-top:-5px;

`;

export const NavLogo = styled(LinkR)
`
color: #2F2F41;
cursor: pointer;
font-size: 2rem;
height: auto;
width: auto;
padding-left: 15px;
position: absolute;
font-weight: bold;
text-decoration: none;

margin-left: 35px;


&:hover{
    color:#9FC756;
}
`;

export const MobileIcon = styled.div `
display: none;
color: #2F2F41;

@media screen and (max-width: 812px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -11px;
    transform: translate(-100% , 60%);
    font-size: 1.8em;
    cursor: pointer;
    color: #2F2F41;
}
`;

export const NavMenu = styled.ul `
flex: 1;
display: flex;
justify-content: center;
margin-left: 110px;
list-style: none;


@media screen and (max-width: 812px) {
    display: none;
}
`;

export const NavItem = styled.li `
height: 80px;
`;

export const NavLinks = styled(LinkS)
`
font-size: 1.2rem;

text-decoration: none;
color: #2F2F41;
display: flex;
margin: 10px;
margin-right:35px;
cursor: pointer;
align-text:center;
justify-content: center;

&:hover{
    color: #9FC756;
}

&.active {
    border-bottom: 3px solid #9FC756;
}
`;

export const NavRight= styled.nav`
display: flex;
justify-content:flex-end;
margin-top:10px;

@media screen and (max-width: 812px){
    display:none;
}
`;

export const NavRightLink = styled(LinkR)`
color: #2F2F41;
padding-right:30px;
font-size: 1.2rem;
text-decoration: none;
cursor:pointer;
&:hover {
    color: #9FC756;
}
`;