import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom';

export const Heading = styled(LinkR)`
text-align: center;
	font-size: 44px;
	font-weight: bold;
	color: white;
	text-decoration: none;

&:hover{
	color: #9FC756;
    	transition: 0.2s ease-in-out;
}
`