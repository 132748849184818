import React from 'react'
import Image1 from '../../Photos/aggroLogo.png';
import Image4 from '../../Photos/Picture1.jpg';

import { PortfolioContainer, PortfolioH2Wrap, PortfolioH1, PortfolioWrapper, PortfolioCard, PortfolioH2, PortfolioP2,PortfolioImg, PortfolioP3, PortfolioP4 } from './portfolioElements';

const PortfolioSection = () => {
	return (
	<>
	<PortfolioContainer id="Portfolio">		
		<PortfolioH1>Portfolio</PortfolioH1>
		<PortfolioWrapper>
			<PortfolioCard>
			<PortfolioImg src={Image4} />
			<PortfolioH2Wrap>
			<PortfolioH2>CAMARICO LAND & DEVELOPMENT LTD</PortfolioH2>
			</PortfolioH2Wrap> 
			<PortfolioP2>REGION: Alberta, Canada</PortfolioP2>
			<PortfolioP3>INDUSTRY: Land Development &amp; Construction Management</PortfolioP3>
			<PortfolioP4>STATUS: Current</PortfolioP4>
			</PortfolioCard>
			<PortfolioCard>
			<PortfolioImg src={Image1} />
			<PortfolioH2Wrap>
			<PortfolioH2>AGGRO MANUFACTURING LTD</PortfolioH2>
			</PortfolioH2Wrap> 
			<PortfolioP2>REGION: Alberta, Canada</PortfolioP2>
			<PortfolioP3>INDUSTRY: Fabrication & Manufacturing</PortfolioP3>
			<PortfolioP4>STATUS: Current</PortfolioP4>
			</PortfolioCard>

			
		</PortfolioWrapper>

	</PortfolioContainer>		
	</>
	)
}

export default PortfolioSection