import React from 'react'
import '../termsPage/termCss/terms.css'
import ScrollToTop from "./ScrollToTopTerm";
import {Heading} from '../termsPage/termStyles.js'

const termsPage = () => {
	return (
		<body class="bodycool">
		<ScrollToTop />
		    <div class="termtitle">
		    <Heading to='/'>Camarico</Heading>
		    <h2>Terms and Conditions</h2>
		    </div>
		    <div class="term">
			<div class="term-form">
			<form id="term-control">
			<h3>EXTENSIVE WEBSITE TERMS AND CONDITIONS</h3>
				<p>
By accessing or using this website (the &quot;Site&quot;) of Camarico Investment Group and its affiliates
(collectively, &quot;Camarico&quot;), you hereby accept and agree to comply with these terms and conditions of
use (collectively, the &quot;Terms and Conditions of Use&quot;). You acknowledge your understanding that these
Terms and Conditions of Use constitute a binding agreement between you and Camarico (sometimes
referred to as &quot;we&quot; or &quot;us&quot;) that governs your access and use of the Site, which includes any
information, data, written materials, images and other content (collectively, &quot;Content&quot;). Please read these Terms and Conditions of Use carefully before using the Site. Each time you use the
Site, your use indicates your full acceptance of and agreement to abide by these Terms and Condition of
Use in their current form. If you do not accept the Terms and Conditions of Use state herein, do not use
the Site and you agree that your sole and exclusive remedy is to discontinue using the Site.
Your compliance with these terms and conditions of use and all other agreements is a condition to your
right to access the Site. Your breach of any provision of these Terms and Conditions of Use will
automatically revoke and terminate your right to access the Site and you will be fully liable for all claims
and causes, regardless of the identity of the injured person, arising from or relating to your continued
use of the Site.
				</p> 
				<h3>LIMITED RIGHT TO USE</h3>  
				<p>Camarico grants you a limited right to use the Site. Your right to use the Site is subject to your
agreement to abide by these Terms and Conditions of Use in their entirety, as well as any other rules,
procedures, policies, terms or conditions that govern all or any portion of the Site. At any time and for
any reason Camarico may revoke your right to use all or any portion of the Site.</p>

				<h3>UPDATES TO SITE</h3>
				<p>Camarico reserves the right to make changes to the Site and these Terms and Conditions of Use at any
time without prior notice to you.</p>
				
				<h3>OWNERSHIP OF MATERIALS ON SITE</h3>
				<p>You may download or copy Content only to the extent such download is expressly permitted in writing
on the Site. No right, title or interest in any downloaded materials is transferred to you as a result of any
such downloading or copying. You may not reproduce (except as noted above), publish, transmit,
distributed, display, modify, create derivative works from, sell or participate in any sale of or exploit in
any way, in whole or part, any of the Content or the Site. Nothing on this Site shall be interpreted as
granting any license or right to use any image, trademark, trade dress, logo or service mark on the Site.
Anything transmitted to the Site by you becomes Camrico’s property and may be used by us for any
lawful purpose. We reserve all rights with respect to copyright and trademark ownership of all material
on the Site.</p>
				<h3>NO OFFERS OR RELIANCE</h3>
				<p>The information on this website is provided for informational purposes only. No Content or other
material on the Site shall be used or considered as an offer to sell or a solicitation of any offer to buy the
securities or services of Camarico or any issuer. Offers can be made only where lawful under, and in
compliance with, applicable law.
Camarico makes no representations that transactions, products or services discussed on the Site are
available or appropriate for sale or use in all jurisdictions or by all investors. Those who access the Site
do so at their own initiative and are responsible for compliance with local laws or regulations. While
Camarico uses reasonable efforts to obtain information from reliable sources, Camarico makes no
representations or warranties as to the accuracy, reliability or completeness of any information or
document on the Site obtained outside of Camarico. Certain information on the Site may contain
forward-looking statements, which are subject risks and uncertainties and speak only as of the date on
which they are made. The words &quot;believe,&quot; &quot;expect,&quot; &quot;anticipate,&quot; &quot;optimistic,&quot; &quot;intend,&quot; &quot;aim,&quot; &quot;will,&quot;
or similar expressions are intended to identify forward-looking statements. Camarico undertakes no
obligation to update or revise any information herein, whether as a result of new information, future
developments or otherwise. Content is subject to change without notice.
Camarico is not utilizing the Site to provide investment or other advice, and no information or material
on the Site is to be deemed a recommendation to buy or sell any securities or is to be relied upon for the
purpose of making or communicating investment or other decisions. In addition, no information,
Content or other materials contained on the Site should be construed or relied upon as investment,
legal, accounting, tax or other professional advice or in connection with any offer or sale of securities.
Any transactions listed on the Site are included as representative transactions and are not necessarily
reflective of overall performance.
Camarico does not advise on the tax consequences of any investment.</p>
				<h3>PAST PERFORMANCE</h3>
				<p>Past performance is not indicative of future results and no representation is being made that any
investment will or is likely to achieve profits or losses similar to those achieved in the past, or that
significant losses will be avoided.</p>

				<h3>NO WARRANTY; LIMITATION ON LIABILITY</h3>
				<p>By using the Site, you expressly agree that such use is at your sole risk. The Site is provided on an &quot;as is,&quot;
&quot;as available&quot; and &quot;with all faults&quot; basis. Neither Camarico nor any of its respective officers, directors,
employees, their affiliates, agents or independent contractors (collectively, &quot;Associates&quot;) warrant that
the use of the Site will be uninterrupted or error-free. Neither Camarico nor its Associates warrant the
accuracy, integrity, completeness, availability or timeliness of the Content provided on the Site now or in
the future. Camarico and its Associates specifically disclaim all applicable warranties regarding the Site.
Under no circumstances will Camarico or its Associates be liable to your or anyone else for any direct,
consequential, incidental, special, exemplary, punitive or other direct or indirect damages that result
from use or loss of use of the Site or its Content.
Any Content downloaded or otherwise obtained through the Site is done at your own discretion and risk
and you are solely responsible for any damage to your computer or other electronic system or loss of

data that results from the download of any such Content. The foregoing limitation of liability will apply
in any action, whether in contract, tort or any other claim.</p>
<h3>THIRD PARTY CONTENT AND LINKED SITES</h3>
<p>References on this Site to any names, marks, products or services of third parties, or hypertext links to
third party sites or information or Content provided by third parties, are provided solely as a
convenience to you and do not in any way constitute or imply our endorsement, sponsorship or
recommendation of the third party, its information, materials or services. We are not responsible for the
practices or policies of such third parties, not the content of any third party sites, and do not make any
representations regarding third party materials or services, or the content or accuracy of any material
on such third party sites. If you choose to link to any third party sites, you do so entirely at your own
risk.</p>
				<h3>TERMINATION OF USE</h3>
				<p>These Terms and Conditions of Use are effective unless and until terminated by either you or Camarico.
You may terminate these Terms and Conditions of Use at any time, provided that you discontinue any
further use of the Site. We also may terminate these Terms and Conditions of Use, in our sole discretion,
at any time and may do so immediately without notice, and accordingly deny you access to the Site.
Upon any termination of these Terms and Conditions of Use by either you or us, you must promptly
destroy all materials downloaded or otherwise obtained from the Site, as well as all copies of such
materials, whether made under these Terms and Conditions of Use or otherwise.</p>
				<h3>INDEMNIFICATION</h3>
				<p>As a condition of your use of the Site, you agree to indemnify and hold Camarico and its Associates from
and against any and all claims, losses, liability, costs and expenses (including but not limited to
attorney’s fees), as incurred, arising from your use of the Site or related services or from your violation
of these Terms and Conditions of Use.</p>

				<h3>REPRESENTATIONS AND WARRANTIES</h3>
				<p>You represent and warrant that: (i) you have full authority and all rights necessary to enter into and fully
perform all of your obligations pursuant to these Terms and Conditions of Use; (ii) you have not and you
will not enter into any agreement or perform any act that might contravene the purposes and/or effects
of these Terms and Conditions of Use; and (iii) you will not delete any Content from the Site.</p>
				<h3>EFFECT ON OTHER AGREEMENTS</h3>
				<p>Nothing contained in these Terms and Conditions of Use is intended to modify or amend any other
agreement you may have with Camarico (&quot;Other Agreements&quot;) if any, that may currently be in effect. In
the event of any inconsistency between these Terms and Conditions of Use and any Other Agreements,
the Other Agreements will govern.</p>
				<h3>GOVERNING LAW</h3>
				<p>Headings used in these Terms and Conditions of Use are for reference purposes only and in no way
define or limit the scope of the section. These Terms and Conditions of Use are governed by the laws of
the Province of Alberta (except for conflict of law provisions). Any claim related to any dispute arising as

a result of the Site or under these Terms and Conditions of Use will be made before a court of
competent jurisdiction located in Alberta. If any provision of these Terms and Conditions of Use is held
to be unenforceable, such provision will be reformed only to the extent necessary to make it
enforceable. The failure of Alberta to act with respect to a breach of these Terms and Conditions of Use
by you or others does not constitute a waiver and will not limit Alberta’s rights with respect to such
breach or any subsequent breaches.</p>

				<h3>ARBITRATION</h3>
				<p>By using the Site, you agree that Alberta may require you to submit any disputes arising from the use of
the Site, related services of these Terms and Conditions of Use concerning or, including disputes arising
from or concerning their interpretation, violation, nullity, invalidity, non-performance or termination, as
well as disputes about filling gaps in this contract or its adaptation to newly arisen circumstances, to
final and binding arbitration under the Alberta Arbitration Act, by one or more arbitrators appointed in
accordance with said rules. Notwithstanding these rules, however, such proceeding shall be governed by
the laws of the province as set forth in the previous section.</p>
			    </form>
			    </div>
			    </div>    
		</body>
	)
}

export default termsPage



