import styled from 'styled-components';

export const AboutContainer = styled.div`
color: purple;
background: white;
width: 100vw;
height: 100vh;


@media screen and (max-width: 768){
	padding: 100px 0;
}
`;

export const AboutWrapper = styled.div`
display: grid;
z-index: 1;
height: 825px;
width: 100vw;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content:center;
`;

export const AboutRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items:center;
grid-template-areas: "col1 col2";

@media screen and (max-width: 768px) {
	grid-template-areas: "col1 col2"
}
`;

export const Column1 = styled.div`
margin-bottom: 55px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
margin-bottom: 55px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;
`;


export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: #2F2F41;
;

@media screen and (max-width: 480px) {
	font-size: 32px;
}
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: #2F2F41;
border-top: solid #2F2F41 1px; 
border-bottom: solid #2F2F41 1px; 
padding-top: 10px;
padding-bottom: 8px;
`;

export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`;

export const Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`;
