import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom';

export const FooterContainer = styled.div`
background-color: white;
height:90px;
width: 100vw;
`;

export const Titleh1 = styled.h1`
font-size: 20px;
padding-top: 15px;
padding-bottom: 15px;
color: #2F2F41;
cursor: pointer;
&:hover {
    color: #9FC756;
}
`;

export const TermsLink = styled(LinkR)`
color: #2F2F41;
font-size: 14px;
text-decoration: none;
cursor:pointer;
&:hover {
    color: #9FC756;
}
`;