import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './Pages'
import contactFormPage from './Pages/contactForm/contactForm'
import termsPage from './Pages/termsPage/termsPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contactForm" component={contactFormPage} exact />
        <Route path="/termsPage" component={termsPage} exact />
      </Switch>
    </Router>
  );
}

export default App;
