import React from 'react'
import { FooterContainer, Titleh1, TermsLink} from './footerElements';
import { animateScroll as scroll} from 'react-scroll'

const toggleHome = () => {
	scroll.scrollToTop();
    };
    

const Footer = ({toggle}) => {
	return (
	<FooterContainer>
		<Titleh1 to='/' onClick={toggleHome}>Camarico</Titleh1>
		<TermsLink to='termsPage'>Terms and Conditions</TermsLink>
	</FooterContainer>
	)
}

export default Footer
