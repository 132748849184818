import React from 'react';
import Image from '../../Photos/invest.svg'


import { AboutContainer, AboutWrapper, AboutRow, Column1, TextWrapper, Heading, Subtitle, Column2,ImgWrap, Img } from './aboutElements';
const AboutSection = () => {
	return (
	<>
	<AboutContainer id="AboutUs">
		<AboutWrapper>
			<AboutRow >
				<Column1>
				<TextWrapper>
					<Heading>What we do here!</Heading>
					<Subtitle>We look to leverage the collective experience of our partners by working together with management teams with the goal of establishing real, sustainable value over multiple years and creating great companies.</Subtitle>
				</TextWrapper>
				</Column1>
				<Column2>
					<ImgWrap>
						<Img src={Image} type='invest/svg' alt='about logo'/>
					</ImgWrap>
				</Column2>
			</AboutRow>
		</AboutWrapper>
	</AboutContainer>
	</>
	)
}

export default AboutSection
