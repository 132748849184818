import React, {useState} from 'react';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar'
import HeroSection from '../Components/Hero';
import AboutSection from '../Components/AboutUs';
import TeamSection from '../Components/OurTeam';
import PortfolioSection from '../Components/Portfolio';
import InvestorSection from '../Components/Investors';
import Footer from '../Components/Footer';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle} />
        <HeroSection />
        <AboutSection />
        <TeamSection />
        <InvestorSection />
        <PortfolioSection />
        <Footer />
        </>
    )
}

export default Home
